import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from "react-router-dom";
import Search from '../../components/common/search/Search';
import MainHeader from '../../components/common/main/header/MainHeader';
import HeaderRevendaDetalhe from '../../components/pages/header/HeaderRevendaDetalhe';
import { API_ADM } from '../../services/ApiAdmin';
import './index.css';
import TabContribuintes from '../../components/common/tabs/TabContribuintes';
import TabelaContribuintes from '../../components/common/table/TableContribuintes';


const RevendaDetalhe = () => {
  const { id } = useParams();
  const [records, setRecords] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("habilitado")




  useEffect(() => {
    fetchData('');
  }, [status]);

  const fetchData = async (value) => {
    try {
      setLoading(true); // Ativar spinner
      const response = await API_ADM.get("/contribuinte/get", {
        params: {
          id_revenda: id,
          fragment: value,
          status
        }
      });

      setRecords(response?.data?.contribuintes);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // Desativar spinner
    }
  };

  const searchInpuChange = (event) => {
    setSearchValue(event.target.value);
  };

  const searchButtonClick = () => {
    fetchData(searchValue);
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchData(searchValue);
    }
  };

  const handleStatus = (status) => {
    setStatus(status)
  }



  return (

    <>
      {/* <HeaderRevendaDetalhe /> */}
      <MainHeader title="Contribuintes">
        <div className='revenda-header'>
          <Search
            searchInputChange={searchInpuChange}
            onKeyDown={handleKeyDown}
            searchButtonClick={searchButtonClick}
          />
        </div>
      </MainHeader>
      <TabContribuintes
        onChange={handleStatus}
        content={(
          <TabelaContribuintes
            data={records}
            count={records.length}
            status={status}
          />
        )}
      />


    </>






  );
};

export default RevendaDetalhe;
