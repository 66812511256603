import axios from "axios";
import {getTokenStorage} from '../services/StorageToken'


export const API_ADM = axios.create({
        baseURL: process.env.REACT_APP_API_ADM,

});


API_ADM.interceptors.request.use(async (config) => {
        const token = getTokenStorage();
        if (token) {
                config.headers.Authorization = `Bearer ${token}`        
                return config
        } else {
                return config
        }
});