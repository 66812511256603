import './index.css';

const ContainerHeader = ({ title, children }) => {
  return (
    <div className='main-content-header'>
      <div className='main-content-header-title'>
        <h2>{title}</h2>
      </div>
      {children}
    </div>
  );
};

export default ContainerHeader;
