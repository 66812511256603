import { Link } from "react-router-dom";

const NoPage = () => {
    return ( 
         <div>
           <h1>Pagina nao encontrada!</h1>
           
           <Link to="/">Pagina Home</Link>
         </div>
        );
} 
export default NoPage;