import { Link } from "react-router-dom";
import { FiMenu, FiCheckCircle } from "react-icons/fi";
import './index.css';
import { FaCheckCircle } from "react-icons/fa";
import { getDate } from "../../../utils";

export default function TabelaContribuintes({ data, count, status }) {
  const hasDetail = status !== "nao habilitado" && String(status).length !== 0

  if (!data) {
    return <div>Carregando dados... ou nenhum dado disponível</div>;
  }

  return (
    <div className="table-container">

      <section className="table-header">
        <div className='table-header-data'> Lista de contribuintes</div>
        <div className='table-header-data'> {count} registros</div>
      </section>


      <section className="table-body">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Dados do Contribuinte</th>
              <th>Cidade</th>
              <th>Contato</th>
              <th>Observações</th>
              <th>Situação</th>
              {hasDetail && <th></th>}
            </tr>

          </thead>
          <tbody>
            {
              data?.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>
                    <div className='text-primary'>{item.fantasia}</div>
                    <div>{item.razao_social}</div>
                    <div>{item.cnpj}</div>
                  </td>
                  <td>{item.cidade}-{item.uf}</td>
                  <td>
                    <div className='text-primary'>{item.contato}</div>
                    <div>{item.email}</div>
                    <div>{item.telefone}</div>
                  </td>
                  <td>
                    <div>
                      <div>{item.assinatura}</div>
                      <div className='text-primary'>{item.dt_expires_assin ? `Expira: ${getDate(item.dt_expires_assin)}` : ""}</div>
                    </div>
                  </td>

                  <td>
                    <i>
                      <FaCheckCircle
                        color={!item.is_habilitado ? "gray.300" : item.vencido ? "orange" : "green"}
                        title={!item.is_habilitado ? "Não habilitado" : item.vencido ? "Expirado" : "Ativo"}
                      />
                    </i>
                  </td>

                  {hasDetail && <td>
                    <Link to={`/contribuintes/${item.id}`}>
                      <i><FiMenu /></i>
                    </Link>
                  </td>}



                </tr>
              ))
            }

          </tbody>
        </table>
      </section>
    </div>
  );
}