import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";
import './index.css';
import { getDate } from "../../../utils";

export default function TabelaContribuintesAll({ data, count }) {
  if (!data) {
    return <div>Carregando dados... ou nenhum dado disponível</div>;
  }

  return (
    <>
      <div className="table-container">

        <section className="table-header">
          <div className='table-header-data'> Lista de contribuintes</div>
          <div className='table-header-data'> {count} registros</div>
        </section>


        <section className="table-body">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Dados do Contribuinte</th>
                <th>Cidade</th>
                <th>Contato</th>
                <th>Assinatura/Expira</th>
                <th>Situação</th>
                {/* <th>Actions</th> */}
              </tr>

            </thead>
            <tbody>
              {
                data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>
                      <div className='text-primary'>{item.fantasia}</div>
                      <div>{item.razao_social}</div>
                      <div>{item.cnpj}</div>
                    </td>

                    <td>{item.cidade}</td>

                    <td>
                      <div className='text-primary'>{item.contato}</div>
                      <div>{item.email}</div>
                      <div>{item.celular}</div>
                    </td>

                    <td>
                      <div>{item.assinatura}</div>
                      <div>{getDate(item.dt_expires_assin)}</div>
                    </td>

                    {/* <td>
                      {item.is_habilitado &&
                        <button
                          onClick={() => {
                            setId(item.id)
                            openModal()
                          }}
                          className="icon-button"
                        >
                          <i>
                            <TbReload title="renovar" />
                          </i>
                        </button>}
                    </td> */}

                    <td style={{lineHeight: 1}}>
                      <i>
                        <FaCheckCircle
                          size={25}
                          color={!item.is_habilitado ? "gray.300" : item.vencido ? "orange" : "green"}
                          title={!item.is_habilitado ? "Não habilitado" : item.vencido ? "Expirado" : "Ativo"}
                        />
                      </i>
                    </td>

                    {/* <td>
                      <Link to={`/contribuintes/${item.id}`}>
                        <i><FiMenu /></i>
                      </Link>
                    </td> */}

                  </tr>
                ))
              }

            </tbody>
          </table>
        </section>
      </div>
    </>
  );
}