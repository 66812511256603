import React, { useState } from 'react';
import './index.css';



export default function TabCobrancas({ onChange, content }) {
  const [activeTab, setActiveTab] = useState(0);

  const tabChange = (index) => {
    setActiveTab(index);
  };

  const tabData = [
    {
      label: 'Aberto',
      status: "aberto",
    },
    {
      label: 'Quitado',
      status: 'quitado',
    },
  ]

  return (
    <div className="tab-container">
      <div className='bloc-tabs'>
        {tabData.map((item, idx) => (
          <div key={idx} className={activeTab === idx ? 'tabs active-tabs' : 'tabs'} onClick={() => {
            tabChange(idx)
            onChange(item.status)
          }}>{item.label}</div>
        ))}
      </div>

      <div className='content-tabs'>
        <div className={'content active-content'}>
          {content}
        </div>
      </div>

    </div>

  );
};
