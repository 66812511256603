// Toast.js
import React, { useState, useEffect } from 'react';
import './index.css';

const Toast = ({ message, duration = 3000, onClose, status = 'default' }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className={`toast toast-${status} ${visible ? 'toast-visible' : 'toast-hidden'}`}>
      <div className="toast-content">{message}</div>
    </div>
  );
};

export default Toast;
