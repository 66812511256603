import { useContext, useEffect, useState } from "react";
import Card from "../../components/common/card/Card";
import { apiCOMMON } from "../../services/api_common";
import { ToastContext } from "../../components/common/toast/ToastContext";
import "./style.css"

export default function Upload() {
    const [selectedFile, setSelectedFile] = useState(null)
    const [versionRelease, setVersionRelease] = useState(0)
    const [loading, setLoading] = useState({
        buttonSend: false,
        buttonUpdate: false
    })

    const { showToast } = useContext(ToastContext);

    const sendFile = async () => {
        // console.log(selectedFile)
        setLoading(old => ({ ...old, buttonSend: true }))
        try {
            // let formData = new FormData()
            // formData.append("release", selectedFile, selectedFile.name)
            const res = await apiCOMMON.postForm("/whatsapp/uploadfile/release/", {
                file: selectedFile
            })
            showToast('Arquivo enviado com sucesso', 3000, 'success');
        } catch (err) {
            showToast(`erro: ${err.response.data.detail[0].msg}`, 3000, 'error');
            throw err
        } finally {
            setLoading(old => ({ ...old, buttonSend: false }))
        }
    }

    const sendVersionRelease = async () => {
        setLoading(old => ({ ...old, buttonUpdate: true }))

        try {
            const res = await apiCOMMON.post("/whatsapp/update/versaofile", {
                version: versionRelease,
            })
            showToast('Versão atualizada com sucesso', 3000, 'success');
        } catch (err) {
            throw err
        } finally {
            setLoading(old => ({ ...old, buttonUpdate: false }))
        }
    }

    const getVersionRelease = async () => {
        try {
            const res = await apiCOMMON.get("/whatsapp/versaofile/")
            // console.log(res.data)
            setVersionRelease(res?.data?.version)
        } catch (err) {
            throw err
        }
    }

    useEffect(() => {
        getVersionRelease()
    }, [])

    return (
        <>
            <div className='main-content-header-title'>
                <h2>Upload Release</h2>
            </div>
            <div style={{ padding: 30 }}>
                <div className="upload-cards">
                    <Card>
                        <span>Selecione o arquivo</span>
                        <div className="flex">
                            <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
                            <button className="button" onClick={sendFile}>
                                Enviar
                            </button>
                        </div>
                    </Card>

                    <Card>
                        <span>Digite o numero da versão</span>
                        <div className="flex">
                            <input
                                type="text"
                                value={versionRelease}
                                onChange={(e) => setVersionRelease(Number(e.target.value) || 0)}
                            />
                            <button className="button" onClick={sendVersionRelease}>
                                Atualizar
                            </button>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    )
}