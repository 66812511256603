import Card from "../../components/common/card/Card";
import { FiTrendingDown, FiTrendingUp } from "react-icons/fi";
import { data } from "./data";
import "./style.css"

export default function Dashboard() {
    return (
        <>
            <div className='main-content-header-title'>
                <h2>Dashboard</h2>
            </div>
            <div style={{ padding: 30 }}>
                <div className="dashboard-cards">
                    {data.map((item) => (
                        <Card key={item.id}>
                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                <span>{item.label}</span>
                                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                                    <strong style={{ fontSize: 18 }}>{item.count}</strong>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: 10,
                                            backgroundColor: item.percent > 50 ? "#3182ce" : "#D69E2E",
                                            color: "#fff",
                                            padding: 3,
                                            borderRadius: 15,
                                            alignItems: "center",
                                            fontSize: "14px",
                                            width: "90px",
                                            justifyContent: "center"
                                        }}
                                    >
                                        {item.percent > 50 ? <FiTrendingUp color="#fff" /> : <FiTrendingDown color="#fff" />}
                                        <strong>{item.percent}%</strong>
                                    </div>
                                </div>
                                <span
                                    style={{
                                        fontSize: "0.75rem",
                                        color: "rgb(140, 140, 140)"
                                    }}
                                >
                                    {item.description}
                                </span>
                            </div>
                        </Card>
                    ))}
                </div>
            </div>
        </>
    )
}