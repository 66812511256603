
export const getTokenStorage = () => {    
    const token = localStorage.getItem('token');
        if (!token) {
            return '';        
        }    
        return token
};


