import { useEffect, useState } from "react";
import Card from "../../components/common/card/Card";
import { useParams } from "react-router-dom";
import { API_ADM } from "../../services/ApiAdmin";
import TabelaCreditos from "../../components/common/table/TableCreditos";
import TabelaAcertos from "../../components/common/table/TableAcertos";
import ModalCustom from "../../components/common/modal/ModalCustom";

export default function RevendaFinanceiro() {
    const [data, setData] = useState(null)
    const [creditos, setCreditos] = useState([])
    const [acertos, setAcertos] = useState([])
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(0)

    const [startDate, setStartDate] = useState(new Date().toISOString().split("T")[0])

    const { id } = useParams()

    const openModal = () => setOpen(true)
    const closeModal = () => setOpen(false)

    const getSaldo = async () => {
        try {
            const res = await API_ADM.get(`/acerto/saldo/${id}`)
            setData(res?.data)
        } catch (err) {
            throw err
        }
    }

    const getAcertos = async () => {
        try {
            const res = await API_ADM.get(`/acerto/get/${id}`)
            // console.log(res.data)
            setAcertos(res.data?.acertos)
            // setSomaAcertos(res.data?.soma_acertos ?? 0)
        } catch (err) {
            throw err
        }
    }

    const salvarAcerto = async () => {
        try {
            const body = {
                id_revenda: id,
                valor_acerto: Number(value) || 0,
                dt_acerto: startDate
            }
            console.log(body.dt_acerto)

            await API_ADM.post("/acerto/post", body)
            setData(old => ({ ...old, acertos: old.acertos + body.valor_acerto, saldo: old.saldo + body.valor_acerto }))
            setAcertos(old => [...old, { ...body, id: old[old.length - 1].id + 1, created_at: startDate }])

            closeModal()
            setValue(0)
            // setStartDate(new Date())
        } catch (err) {
            throw err
        }
        // setAcertos(old => [...old, { id: 12321123, valor_acerto: 122, created_at: new Date() }])
    }

    const removeAcerto = (index) => {
        let id = acertos[index].id
        let valor_acerto = acertos[index].valor_acerto

        setAcertos(old => old.filter(item => item.id !== id))
        setData(old => ({ ...old, acertos: old.acertos - valor_acerto, saldo: old.saldo + valor_acerto }))
    }

    const getCreditos = async () => {
        try {
            const res = await API_ADM.get(`/acerto/creditos/${id}`)
            setCreditos(res.data?.cobrancas)
            // setSomaCreditos(res.data?.soma_creditos ?? 0)
        } catch (err) {
            throw err
        }
    }

    useEffect(() => {
        getAcertos()
        getSaldo()
        getCreditos()
    }, [])

    return (
        <>
            <ModalCustom isOpen={open} closeModal={closeModal} label={"Novo Acerto"} content={(
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 15 }}>
                    <span>Valor</span>
                    <input
                        value={value}
                        onChange={(e) => Number(setValue(e.target.value)) || 0}
                        style={{ width: 100 }}
                    />
                    <span>Data do pagamento</span>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    // onBlur={(e) => console.log(e.target.value)}
                    />
                    <div
                        className="button"
                        style={{ width: 85, padding: 7 }}
                        onClick={salvarAcerto}
                    >
                        Confirmar
                    </div>
                </div>
            )} />

            <div className="finance-cards">
                <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                    <Card>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: 10,
                                marginTop: 10
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: 10,
                                }}>
                                <div
                                    style={{
                                        color: 'var(--btn-success)',
                                        border: "1px solid var(--btn-success)",
                                        borderRadius: 20,
                                        padding: "5px 5px 5px",
                                        fontWeight: 600,
                                        fontSize: 12
                                    }}
                                >
                                    <span>Créditos: R$ {data?.creditos.toFixed(2).replace(".", ",")}</span>
                                </div>

                                <div
                                    style={{
                                        color: 'var(--btn-danger)',
                                        border: "1px solid var(--btn-danger)",
                                        borderRadius: 20,
                                        padding: "5px 5px 5px",
                                        fontWeight: 600,
                                        fontSize: 12
                                    }}
                                >
                                    <span>Acertos: R$ {data?.acertos.toFixed(2).replace(".", ",")}</span>
                                </div>
                            </div>

                            <div
                                style={{
                                    color: 'var(--btn-info)',
                                    border: "1px solid var(--btn-info)",
                                    borderRadius: 20,
                                    padding: "5px 5px 5px",
                                    fontWeight: 600,
                                    fontSize: 12
                                }}
                            >
                                <span>Saldo: R$ {data?.saldo.toFixed(2).replace(".", ",")}</span>
                            </div>
                        </div>
                    </Card>
                </div>

                <Card>
                    {/* <h3>Acertos</h3> */}
                    <TabelaAcertos
                        data={acertos}
                        count={acertos.length}
                        removeAcerto={removeAcerto}
                        openModalNovoAcerto={openModal}
                    />
                </Card>
                <Card>
                    {/* <h3>Créditos</h3> */}
                    <TabelaCreditos data={creditos} count={creditos.length} />
                </Card>
            </div>
        </>
    )
}