import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { API_ADM } from "../../services/ApiAdmin"
import TabelaCobrancas from "../../components/common/table/TableCobrancas"
import { FiArrowLeft } from "react-icons/fi"
import { TbReload } from "react-icons/tb"
import { ToastContext } from "../../components/common/toast/ToastContext"
import ModalCustom from "../../components/common/modal/ModalCustom"

export default function ContribuinteDetalhes() {
    const [loading, setLoading] = useState(false)
    const [cobrancas, setCobrancas] = useState([])
    const [open, setOpen] = useState(false)

    const navigate = useNavigate()
    const { id } = useParams()

    const openModal = () => setOpen(true)
    const closeModal = () => setOpen(false)

    const { showToast } = useContext(ToastContext);


    const renovarAssinatura = async () => {
        try {
            const renovacao = await API_ADM.post(`/cobranca/assinatura/renovacao?id_contribuinte=${cobrancas[0].id_contribuinte}`)

            showToast(renovacao.data.msg, 3000, 'success');
            closeModal()
        } catch (err) {
            showToast(`erro: ${err?.response?.data?.detail}`, 3000, 'error');
        }
    }

    const getCobrancas = async () => {
        setLoading(true)

        try {
            const res = await API_ADM.get(`cobranca/get/contribuinte/${id}`)
            setCobrancas(res?.data.cobrancas)
            // console.log(res)
        } catch (err) {
            //   ToastChakra('Erro', "Erro ao carregar os dados", 'error', 3000, 'top-right')
            throw err
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getCobrancas()
    }, [])

    return (
        <>
            <ModalCustom
                isOpen={open}
                closeModal={closeModal}
                label={"Renovar Assinatura"}
                content={(
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 10 }}>
                        <span>Deseja renovar a assinatura?</span>
                        <div
                            className="button"
                            style={{ width: 85, padding: 7 }}
                            onClick={renovarAssinatura}
                        >
                            Confirmar
                        </div>
                    </div>
                )}
            />

            <div
                className="card"
                style={{
                    marginBottom: "1rem",
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    maxHeight: "50px",
                    justifyContent: "space-between"
                }}>
                <span style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                }}>
                    <button
                        className="icon-button"
                        style={{
                            borderRadius: "50%",
                            width: "30px",
                        }}
                        onClick={() => navigate(-1)}
                    >
                        <FiArrowLeft size={20} />
                    </button>
                    <h2>{cobrancas[0]?.fantasia}</h2>
                </span>
                {id == cobrancas[0]?.id_contribuinte && <button
                    onClick={() => {
                        openModal()
                        // console.log(id, data[0].id_contribuinte)
                    }}
                    className="icon-button"
                    style={{ fontSize: "11px", width: "150px" }}
                >
                    <i>
                        <TbReload size={20} title="renovar" />
                    </i>
                    Renovar assinatura
                </button>}
            </div>
            <TabelaCobrancas data={cobrancas} count={cobrancas.length} />
        </>
    )
}