import { Link } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { MdOutlineAddCircleOutline } from "react-icons/md";

import './index.css';
import ModalCustom from "../modal/ModalCustom";
import { useContext, useState } from "react";
import { API_ADM } from "../../../services/ApiAdmin";
import { ToastContext } from "../toast/ToastContext";
import { getDate } from "../../../utils";

export default function TabelaAcertos({ data, count, removeAcerto, openModalNovoAcerto, total }) {
    const [open, setOpen] = useState(false)
    const [acertoIndex, setAcertoIndex] = useState(-1)

    const { showToast } = useContext(ToastContext);

    if (!data) {
        return <div>Carregando dados... ou nenhum dado disponível</div>;
    }

    const openModal = () => setOpen(true)
    const closeModal = () => setOpen(false)

    const deleteAcerto = async () => {
        try {
            await API_ADM.delete(`/acerto/delete/${data[acertoIndex].id}`)
            showToast('Acerto excluído com sucesso!', 3000, 'success');
            removeAcerto(acertoIndex)
            closeModal()
        } catch (err) {
            console.error(err)
            showToast(`erro: ${err?.response?.data?.detail}`, 3000, 'error');
        }
    }

    return (
        <>
            <ModalCustom isOpen={open} closeModal={closeModal} label={"Excluir Acerto"} content={(
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 10 }}>
                    <span>Deseja realmente excluir o acerto?</span>
                    <div
                        className="button"
                        style={{ width: 85, padding: 7 }}
                        onClick={deleteAcerto}
                    >
                        Confirmar
                    </div>
                </div>
            )} />
            <div className="table-container">

                <section className="table-header">
                    <div className='table-header-data'>
                        <span>Acertos </span>
                        {openModalNovoAcerto && <MdOutlineAddCircleOutline
                            id="btn"
                            size={"1.2rem"}
                            title="Novo Acerto"
                            onClick={openModalNovoAcerto}
                        />}
                    </div>
                    <div className='table-header-data'> {count} registros</div>
                    {total && <div
                        style={{
                            gap: 10,
                            backgroundColor: "#3182ce",
                            color: "#fff",
                            padding: "3px 15px",
                            borderRadius: 15,
                            fontSize: "13px",
                            // width: "140px",
                        }}
                    >
                        Total R$: {total.toFixed(2).replace(".", ",") || "0,00"}
                    </div>}

                </section>


                <section className="table-body">
                    <table>
                        <thead>
                            <tr>
                                <th>DT pagamento</th>
                                <th>Valor pago</th>
                                <th></th>
                            </tr>

                        </thead>
                        <tbody>
                            {
                                data?.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{getDate(item.dt_acerto || "")}</td>

                                        <td>
                                            <div>{item.valor_acerto.toFixed(2).replace(".", ",")}</div>
                                        </td>

                                        <td>
                                            {removeAcerto && <FiTrash
                                                id="btn"
                                                size={"1.2rem"}
                                                title="Excluir Acerto"
                                                onClick={() => {
                                                    setAcertoIndex(index)
                                                    openModal()
                                                }}
                                            />}
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </section>
            </div>
        </>
    );
}