import React, { useState } from 'react';
import './index.css';



export default function TabContribuintes({ onChange, content }) {
  const [activeTab, setActiveTab] = useState(0);

  const tabChange = (index) => {
    setActiveTab(index);
  };

  const tabData = [
    {
      label: 'Habilitados',
      status: "habilitado",
    },
    {
      label: 'Ativos',
      status: 'ativo',
    },
    {
      label: 'Bloqueados',
      status: 'bloqueado',
    },
    {
      label: 'Todos',
      status: "",
    },
    {
      label: 'Não habilitados',
      status: 'nao habilitado',
    },
  ]

  return (
    <div className="tab-container">
      <div className='bloc-tabs'>
        {tabData.map((item, idx) => (
          <div key={idx} className={activeTab === idx ? 'tabs active-tabs' : 'tabs'} onClick={() => {
            tabChange(idx)
            onChange(item.status)
          }}>
            <span>{item.label}</span>
          </div>
        ))}
      </div>

      <div className='content-tabs'>
        <div className={'content active-content'}>
          {content}
        </div>
      </div>

    </div>

  );
};
