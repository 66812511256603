import React from 'react';
import { AuthProvider } from './context/AuthContext';
import { ToastProvider } from './components/common/toast/ToastContext';
import Rotas from "./routes";




const App = () => {
  return (
    <AuthProvider>
      <ToastProvider>
        <Rotas />
      </ToastProvider>
    </AuthProvider> 
  );
};

export default App;