import { useEffect, useState } from "react";
import TabelaSaldo from "../../components/common/table/TableSaldo";
import { API_ADM } from "../../services/ApiAdmin";
import Spinner from "../../components/common/spinner/Spinner";

export default function Financeiro() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getSaldo = async () => {
        setLoading(true)

        try {
            const res = await API_ADM.get(`/acerto/totalizadores`)
            // console.log(res.data.totalizacao)
            setData(res.data.totalizacao)
            // console.log(res.data)
        } catch (err) {
            throw err
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getSaldo()
    }, [])

    return (
        <>
            {loading? <Spinner /> : <TabelaSaldo data={data} count={data.length} /> }
        </>
    )
}