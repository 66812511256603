import { useEffect, useState } from "react";
import TabelaCreditos from "../../components/common/table/TableCreditos";
import { API_ADM } from "../../services/ApiAdmin";
import Spinner from "../../components/common/spinner/Spinner";

export default function Creditos() {
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)

    const getCreditos = async () => {
        try {
            setLoading(true)
            
            const res = await API_ADM.get(`/acerto/creditos/${1}`)
            // console.log(res.data)
            setData(res.data?.cobrancas)
            setTotal(res.data?.soma_creditos)
        } catch (err) {
            throw err
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getCreditos()
    }, [])

    return (
        <>
            {loading? <Spinner /> : <TabelaCreditos data={data} count={data.length} total={total} /> }
        </>
    )
}