import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import './index.css';
import { getDate } from "../../../utils";

export default function TabelaCreditos({ data, count, total }) {
    if (!data) {
        return <div>Carregando dados... ou nenhum dado disponível</div>;
    }

    return (
        <div className="table-container">

            <section className="table-header">
                <div className='table-header-data'> Créditos</div>
                <div className='table-header-data'> {count} registros</div>
                {total && <div
                        style={{
                            gap: 10,
                            backgroundColor: "#3182ce",
                            color: "#fff",
                            padding: "3px 15px",
                            borderRadius: 15,
                            fontSize: "13px",
                            // width: "140px",
                        }}
                    >
                        Total R$: {total.toFixed(2).replace(".", ",") || "0,00"}
                    </div>}
            </section>


            <section className="table-body">
                <table>
                    <thead>
                        <tr>
                            <th>CNPJ</th>
                            <th>Cidade</th>
                            <th>Quitação</th>
                            <th>Vencimento</th>
                            <th>Situação</th>
                            <th>Valor cliente</th>
                            <th>Valor revenda</th>
                        </tr>

                    </thead>
                    <tbody>
                        {
                            data?.map((item) => (
                                <tr key={item.id}>
                                    <td>
                                        <div className='text-primary'>{item.fantasia}</div>
                                        <div>{item.razao_social}</div>
                                        <div>{item.cnpj}</div>
                                    </td>

                                    <td>{item.cidade}-{item.uf}</td>

                                    <td>
                                        <div>{getDate(item.quitacao)}</div>
                                    </td>

                                    <td>
                                        <div>{getDate(item.vencimento)}</div>
                                    </td>

                                    <td>
                                        <div>{item.situacao}</div>
                                    </td>

                                    <td>
                                        <div>{item.valor.toFixed(2).replace(".", ",")}</div>
                                    </td>

                                    <td>
                                        <div>{item.valor_revenda.toFixed(2).replace(".", ",")}</div>
                                    </td>

                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </section>
        </div>
    );
}