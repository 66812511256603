import { getDate } from '../../../utils';
import './index.css';

export default function TabelaCobrancas({ data, count }) {
    if (!data) {
        return <div>Carregando dados... ou nenhum dado disponível</div>;
    }

    return (
        <>
            <div className="table-container">
                <section className="table-header">
                    <div className='table-header-data'>

                        Cobranças
                    </div>
                    <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                        
                        <div className='table-header-data'> {count} registros</div>
                    </div>
                </section>


                <section className="table-body">
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Dados do Contribuinte</th>
                                <th>Cidade</th>
                                <th>Situação</th>
                                <th>Emissão</th>
                                <th>Vencimento</th>
                                <th>Valor</th>
                                <th>Valor da Revenda</th>
                            </tr>

                        </thead>
                        <tbody>
                            {
                                data?.map((item, idx) => (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>
                                            <div className='text-primary'>{item.fantasia}</div>
                                            <div>{item.razao_social}</div>
                                            <div>{item.cnpj}</div>
                                        </td>
                                        <td>{item.cidade}-{item.uf}</td>

                                        <td>
                                            <div
                                                className="badge"
                                                style={{
                                                    color: 'var(--text-white)',
                                                    backgroundColor: item.situacao === "aberto" ? '#ff4a4a' : 'var(--btn-success)',
                                                    borderRadius: 5,
                                                    padding: 5,
                                                    opacity: 0.8,
                                                    // width: "55%"
                                                }}
                                            >
                                                <i>
                                                    {item.situacao}
                                                </i>
                                            </div>
                                        </td>

                                        <td>
                                            <div>{getDate(item.emissao)}</div>
                                        </td>

                                        <td>
                                            <div>{getDate(item.vencimento)}</div>
                                        </td>

                                        <td>
                                            <div>{item.valor}</div>
                                        </td>

                                        <td>
                                            <div>{item.valor_revenda}</div>
                                        </td>

                                        {/* <td>
                    <i>
                      <FiCheckCircle
                        color={!item.is_habilitado ? "gray.300" : item.vencido ? "orange" : "green"}
                        title={!item.is_habilitado ? "Não habilitado" : item.vencido ? "Expirado" : "Ativo"}
                      />
                    </i>
                  </td> */}

                                        {/* <td>
                                        <Link to='/revendas/detalhe'>
                                            <i><FiMenu /></i>
                                        </Link>
                                    </td> */}

                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </section>
            </div>
        </>
    );
}