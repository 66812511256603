import React from 'react';
import LoginInscricao from '../../../components/layout/login/LoginInscricao';

const LoginInscricaoPage = () => {
  return (
    <div>
      <LoginInscricao />
    </div>
  );
};

export default LoginInscricaoPage;
