import React, { useEffect, useRef, useState } from 'react';
import TableRevendas from '../../components/common/table/TableRevendas';
import ModalCustom from '../../components/common/modal/ModalCustom';
import Spinner from '../../components/common/spinner/Spinner';
import MainHeader from '../../components/common/main/header/MainHeader';
import { API_ADM } from '../../services/ApiAdmin';
import Search from '../../components/common/search/Search';
import './index.css';
import { AiOutlineCopy } from "react-icons/ai";

const Revenda = () => {
  const [records, setRecords] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [token, setToken] = useState('');

  const textareaRef = useRef(null);



  useEffect(() => {
    fetchData('');
  }, []);




  const fetchData = async (value) => {
    try {
      setLoading(true); // Ativar spinner
      const response = await API_ADM.get(`/revendas/get`,
        {
          params: {
            fragment: value
          }
        });

      setRecords(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // Desativar spinner
    }
  };

  const searchInpuChange = (event) => {
    setSearchValue(event.target.value);
  };

  const searchButtonClick = () => {
    fetchData(searchValue);
  };

  const openModal = () => {
    setIsModal(true);
  };

  const closeModal = () => {
    setIsModal(false);
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchData(searchValue);
    }
  };

  const gerarToken = async () => {
    try {
      const baseURL = process.env.REACT_APP_API_ADM;
      const response = await API_ADM.post(`${baseURL}/assinatura/token/gerar`);
      const novoToken = response.data.access_token;
      setToken(novoToken);

      // local
      // const linkInscricao = `http://localhost:3000/inscricao?token-insc=${novoToken}`; 

      // producao
      const linkInscricao = `${process.env.REACT_APP_WEB_ADMIN}/inscricao?token=${novoToken}`;



      textareaRef.current.value = linkInscricao;
    } catch (error) {
      console.error('Erro ao gerar token:', error.message);
    }
  };

  const handleCopyToClipboard = () => {
    if (textareaRef.current) {
      textareaRef.current.select();
      document.execCommand('copy');
    }
  };


  return (

    <>
      <MainHeader title="Revendas">
        <div className='revenda-header'>
          <Search searchInputChange={searchInpuChange} onKeyDown={handleKeyDown} searchButtonClick={searchButtonClick} />
          {/* <div className='revenda-header-btn-inscricao'>
            <button onClick={openModal} className='button-dark'>Inscrição de Revenda</button>
          </div> */}
        </div>
      </MainHeader>

      {loading ? <Spinner /> : <TableRevendas data={records.revendas} count={records.count} />}
      <ModalCustom isOpen={isModal} closeModal={closeModal} label={"Inscrição de Revenda"} content={(
        <>
          <button onClick={gerarToken} className='modal-button-insc button-dark'>Gerar link de inscrição</button>
          <div className='modal-textarea'>
            <div className='modal-textarea-itens'>
              <textarea ref={textareaRef} className="textarea" name='textarea-inscricao' readOnly />
              <AiOutlineCopy onClick={handleCopyToClipboard} />
            </div>
          </div>
        </>
      )} />

    </>

  );
};

export default Revenda;
