// SearchComponent.js
import React from 'react';
import { FiSearch } from "react-icons/fi";
import Input from '../input/Input'; 
import './index.css';

const Search = ({ searchInputChange, onKeyDown, searchButtonClick }) => {
  return (
    <div className='search-header'>                
      <div className='search-header-itens'>
        <Input searchInputChange={searchInputChange} onKeyDown={onKeyDown} placeholder="Digite alguma coisa..." name="fragment" icon={<FiSearch  />} />
        <button onClick={searchButtonClick} className='button'>Pesquisar</button>                                                    
      </div>
    </div>
  );
};

export default Search;
