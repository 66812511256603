import React from 'react';
import Layout from '../../../components/layout/home/Layout'; // Certifique-se de ajustar o caminho do import conforme necessário

const Home = () => {
  return (
    <div>
      <Layout />
    </div>
  );
};

export default Home;
