import Modal from 'react-modal';
import { FiX } from "react-icons/fi";
import './index.css';

Modal.setAppElement("#root")

export default function ModalCustom({ isOpen, closeModal, label, content }) {
  return (
    <div className={`container-modal ${isOpen ? 'modal-open' : ''}`}>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        overlayClassName="modal-overlay"
        className="modal-content"
        contentLabel="label"
        >

        <div className='modal-header'>
          <h3>{label}</h3>
          <button onClick={closeModal} className='button-dark-close'><FiX /></button>
        </div>
        <div className='modal-body'>
          <div className='modal-main'>
            {content}
          </div>
        </div>


      </Modal>
    </div>
  );
};
