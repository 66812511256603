// import { BrowserRouter, Routes, Route } from "react-router-dom";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./pages/layout/home/Home";
import LoginPage from "./pages/layout/login/LoginPage";
import LoginInscricaoPage from "./pages/layout/login/LoginInscricaoPage";
import NoPage from "./pages/layout/nopage/NoPage";
import Revenda from "./pages/revenda/Revenda";
import RevendaDetalhe from "./pages/revenda/RevendaDetalhe";
import RouteSecurity from "./context/RouteSecurity";
import DetalheRevendaLayout from "./components/layout/detalhe-revenda";
import RevendaCobrancas from "./pages/revenda/RevendaCobrancas";
import RevendaFinanceiro from "./pages/revenda/RevendaFinanceiro";
import RevendaDados from "./pages/revenda/RevendaDados";
import Dashboard from "./pages/dashboard";
import Contribuintes from "./pages/contribuintes";
import Cobrancas from "./pages/cobrancas";
import Financeiro from "./pages/financeiro";
import Upload from "./pages/upload";
import FinanceiroLayout from "./components/layout/financeiro";
import Creditos from "./pages/financeiro/creditos";
import Acertos from "./pages/financeiro/acertos";
import ContribuinteDetalhes from "./pages/contribuintes/detalhe";



const Rotas = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RouteSecurity><Home /></RouteSecurity>,
      children: [
        {
          index: true,
          element: <RouteSecurity><Dashboard /></RouteSecurity>
        },
        {
          path: "revendas",
          element: <RouteSecurity><Revenda /></RouteSecurity>,
        },
        {
          path: "contribuintes",
          element: <RouteSecurity><Contribuintes /></RouteSecurity>,
        },
        {
          path: "contribuintes/:id",
          element: <ContribuinteDetalhes />
        },
        {
          path: "cobrancas",
          element: <RouteSecurity><Cobrancas /></RouteSecurity>
        },
        {
          path: "financeiro",
          element: <RouteSecurity><FinanceiroLayout /></RouteSecurity>,
          children: [
            {
              index: true,
              element: <RouteSecurity><Financeiro /></RouteSecurity>
            },
            {
              path: "creditos",
              element: <RouteSecurity><Creditos /></RouteSecurity>
            },
            {
              path: "acertos",
              element: <RouteSecurity><Acertos /></RouteSecurity>
            }
          ]
        },
        {
          path: "upload",
          element: <RouteSecurity><Upload /></RouteSecurity>
        },
        {
          path: "revendas/detalhe/:id",
          element: <RouteSecurity><DetalheRevendaLayout /></RouteSecurity>,
          children: [
            {
              index: true,
              element: <RouteSecurity><RevendaDetalhe /></RouteSecurity>,
            },
            {
              path: "cobrancas",
              element: <RouteSecurity><RevendaCobrancas /></RouteSecurity>
            },
            {
              path: "financeiro",
              element: <RouteSecurity><RevendaFinanceiro /></RouteSecurity>
            },
            {
              path: "dados",
              element: <RouteSecurity><RevendaDados /></RouteSecurity>
            }
          ]
        },


      ]
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "inscricao",
      element: <LoginInscricaoPage />,
    },
    {
      path: "*",
      element: <NoPage />,
    },
  ]);




  return (
    <RouterProvider router={router} />

  );
};

export default Rotas;