import React, { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { FiLogOut } from 'react-icons/fi';
import './index.css';

const Logout = () => {
  const { logout } = useContext(AuthContext);

  return (
    <button className="logout-user" onClick={logout}>
      <i><FiLogOut /></i>
    </button>
  );
};

export default Logout;
