import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(() => {
    const token = localStorage.getItem('token');
    return token && token !== 'undefined' ? jwtDecode(token) : null;
  });

  // const [user, setUser] = useState(null)


  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        
        if (decodedToken.exp * 1000 < Date.now()) {
          localStorage.removeItem('token');
          setUser(null);
        }
      } catch (error) {
        console.error('Erro na decodificação do token:', error);
        localStorage.removeItem('token');
      }
    }
  }, []);

  // useEffect(() => {
  //   setUser(localStorage.getItem('user'))
  // },[])

  const login = (token, data) => {
    localStorage.setItem('token', token);
    const payload = jwtDecode(token)
    // console.log(payload)
    setUser(payload);
    // console.log(data)
    // setUser(data)
    // localStorage.setItem('user', data);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
