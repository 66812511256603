import { Link, NavLink } from "react-router-dom";
import { CgReorder, CgHome, CgKeyboard, CgClose } from 'react-icons/cg'
import { FiPaperclip, FiFileMinus, FiPhoneCall, FiUser, FiMenu } from "react-icons/fi";
import logo from '../../../assets/imgs/logo-rota-2024.png';
import React, { useState } from 'react';
import './index.css';

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState('');
  const [open, setOpen] = useState(false)


  const handleLinkClick = (linkId) => {
    setActiveLink(linkId);
  };


  return (
    <div>


      <div className='sidebar-container'>
        <div className='sidebar-header'>

          <span className="sidebar-header">
            <img src={logo} alt='sidebar-logo' />

            <div className='sidebar-header-text'>
              <div className='sidebar-logo-text1'>Rota</div>
              <div className='sidebar-logo-text2'>tecnologia</div>
            </div>
          </span>

          <div
            className="toggle-menu"
            onClick={() => setOpen(prev => !prev)}
          >
            {open ? <CgClose size={30} /> : <FiMenu size={30} />}
          </div>
        </div>

        <div className={`menu-sidebar ${open ? "open" : ""}`}>
          <ul>
            <li className={"sidebar-nav-link"}>
              <NavLink to={""} className={({ isActive }) => isActive ? "active" : ""}>
                <i><CgHome /></i>
                <div className='nav-link'>Dashboard</div>
              </NavLink>
            </li>

            <li className={"sidebar-nav-link"}>
              <NavLink to={"revendas"} className={({ isActive }) => isActive ? "active" : ""}>
                <i><CgReorder /></i>
                <div className='nav-link'>Revendas</div>
              </NavLink>
            </li>

            <li className={"sidebar-nav-link"}>
              <NavLink to={"contribuintes"} className={({ isActive }) => isActive ? "active" : ""}>
                <i><FiFileMinus /></i>
                <div className='nav-link'>Contribuintes</div>
              </NavLink>
            </li>

            <li className={"sidebar-nav-link"}>
              <NavLink to={"cobrancas"} className={({ isActive }) => isActive ? "active" : ""}>
                <i><FiPhoneCall /></i>
                <div className='nav-link'>Cobranças</div>
              </NavLink>
            </li>

            <li className={"sidebar-nav-link"}>
              <NavLink to={"financeiro"} className={({ isActive }) => isActive ? "active" : ""}>
                <i><CgKeyboard /></i>
                <div className='nav-link'>Recorrência (-)</div>
              </NavLink>
            </li>

            <li className={"sidebar-nav-link"}>
              <NavLink to={"upload"} className={({ isActive }) => isActive ? "active" : ""}>
                <i><FiPaperclip /></i>
                <div className='nav-link'>Upload Release</div>
              </NavLink>
            </li>

          </ul>


        </div>
      </div>
    </div>
  );
}

export default Sidebar;