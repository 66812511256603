import { CgUserList } from "react-icons/cg";
import { NavLink, Outlet } from "react-router-dom";
import { CiMoneyBill } from "react-icons/ci";
import { MdAttachMoney } from "react-icons/md";
import { CiMoneyCheck1 } from "react-icons/ci";

export default function FinanceiroLayout() {
    return (
        <div>
            <div className='page-header-nav'>
                <ul>
                    <li>
                        <NavLink to={""} end>
                            <i><CiMoneyBill /></i>
                            Saldo Disponível
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"creditos"} end>
                            <i><MdAttachMoney /></i>
                            Créditos
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"acertos"} end>
                            <i><CiMoneyCheck1 /></i>
                            Acertos
                        </NavLink>
                    </li>
                </ul>
            </div>
            <Outlet />
        </div>
    )
}