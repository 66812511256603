import { useEffect, useState } from "react";
import TabelaCobrancas from "../../components/common/table/TableCobrancas";
import TabCobrancas from "../../components/common/tabs/TabCobrancas";
import { API_ADM } from "../../services/ApiAdmin";
import MainHeader from '../../components/common/main/header/MainHeader';
import Search from "../../components/common/search/Search";
import Spinner from "../../components/common/spinner/Spinner";

export default function Cobrancas() {
    const [records, setRecords] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("aberto")

    const fetchData = async (fragment) => {
        setLoading(true)
  
      try {
        const res = await API_ADM.get("/cobranca/get", {
          params: {
            fragment,
            situacao: status
          }
        })
        // console.log(res)
        setRecords(res?.data?.cobrancas)
        // console.log(res?.data?.cobrancass)
      } catch (err) {
        throw err
      } finally {
        setLoading(false)
      }
    };

    const searchInpuChange = (event) => {
        setSearchValue(event.target.value);
    };

    const searchButtonClick = () => {
        fetchData(searchValue);
    };


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            fetchData(searchValue);
        }
    };

    const handleStatus = (status) => {
        setStatus(status)
    }

    useEffect(() => {
        fetchData(null)
      }, [status])

    return(
        <>
            <MainHeader title="Cobranças">
                <div className='revenda-header'>
                    <Search searchInputChange={searchInpuChange} onKeyDown={handleKeyDown} searchButtonClick={searchButtonClick} />
                </div>
            </MainHeader>

            <TabCobrancas
                onChange={handleStatus}
                content={(
                    loading ? <Spinner /> : <TabelaCobrancas
                        data={records}
                        count={records.length}
                    />
                )}
            />

        </>
    )
}