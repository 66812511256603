import { Outlet } from "react-router-dom";
import HeaderRevendaDetalhe from "../../pages/header/HeaderRevendaDetalhe";

export default function DetalheRevendaLayout() {
    return (
        <>
            <HeaderRevendaDetalhe />
            <Outlet />
        </>
    )
}