import { useEffect, useState } from "react"
import { API_ADM } from "../../services/ApiAdmin"
import TabelaAcertos from "../../components/common/table/TableAcertos"
import Spinner from "../../components/common/spinner/Spinner"

export default function Acertos() {
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)

    const getAcertos = async () => {
        try {
            const res = await API_ADM.get(`/acerto/get/${1}`)
            // console.log(res.data)
            setData(res.data?.acertos)
            setTotal(res.data?.soma_acertos)
        } catch (err) {
            throw err
        }
    }

    // const removeAcerto = (index) => {
    //     let id = data[index].id
    //     setData(old => old.filter(item => item.id !== id))
    // }

    useEffect(() => {
        getAcertos()
    }, [])

    return (
        <>
            {loading ? <Spinner /> :
                <TabelaAcertos
                    data={data}
                    count={data.length}
                    // removeAcerto={removeAcerto}
                    total={total}
                />
            }
        </>
    )
}