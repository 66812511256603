import { useContext, useEffect, useState } from "react";
import MainHeader from '../../components/common/main/header/MainHeader';
import Search from "../../components/common/search/Search";
import Spinner from "../../components/common/spinner/Spinner";
import TabelaContribuintesAll from "../../components/common/table/TableContribuintesAll";
import { API_ADM } from "../../services/ApiAdmin";
import { AuthContext } from "../../context/AuthContext";
import TabContribuintes from "../../components/common/tabs/TabContribuintes";

export default function Contribuintes() {
    const [records, setRecords] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("habilitado")
    // const { user } = useContext(AuthContext);

    const fetchData = async (fragment) => {
        setLoading(true)
        try {
            const res = await API_ADM.get("/contribuinte/get", {
                params: {
                    // id_revenda: user.id_revenda || user.user_id,
                    fragment,
                    status
                }
            })
            setRecords(res?.data?.contribuintes)
        } catch (err) {
            throw err
        } finally {
            setLoading(false)
        }
    };

    const searchInpuChange = (event) => {
        setSearchValue(event.target.value);
    };

    const searchButtonClick = () => {
        fetchData(searchValue);
    };


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            fetchData(searchValue);
        }
    };

    const handleStatus = (status) => {
        setStatus(status)
    }

    useEffect(() => {
        fetchData(null)
    }, [status])

    return (
        <>
            <MainHeader title="Contribuintes">
                <div className='revenda-header'>
                    <Search searchInputChange={searchInpuChange} onKeyDown={handleKeyDown} searchButtonClick={searchButtonClick} />
                </div>
            </MainHeader>
        
            <TabContribuintes
                onChange={handleStatus}
                content={(
                    loading ? <Spinner /> :
                        <TabelaContribuintesAll
                            data={records}
                            count={records.length}
                        />
                )}
            />

        </>
    )
}