import { FaUser } from "react-icons/fa";

export const data = [
    {
        id: 1,
        label: "Clientes",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
        icon: FaUser,
        count: 22345,
        percent: 59.3,
    },
    {
        id: 2,
        label: "Clientes ativos",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
        icon: FaUser,
        count: 32144,
        percent: 70.5,
    },
    {
        id: 3,
        label: "Clientes inativos",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
        icon: FaUser,
        count: 74231,
        percent: 27.4,
    }
]