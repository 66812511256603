import { useContext, useEffect, useState } from "react";
import Card from "../../components/common/card/Card";
import { API_ADM } from "../../services/ApiAdmin";
import { useParams } from "react-router-dom";
import { ToastContext } from "../../components/common/toast/ToastContext";

export default function RevendaDados() {
    const [data, setData] = useState(null)

    const { id } = useParams()
    const { showToast } = useContext(ToastContext);

    const handleChange = (event) => {
        const { name, value } = event.target
        setData(old => ({ ...old, [name]: value }))
    }

    const getById = async () => {
        try {
            const res = await API_ADM.get(`revendas/get/${id}`)
            const obj = res?.data?.revendas[0]

            setData(obj)
        } catch (err) {
            console.log(err)
        }
    }


    const handleSave = async () => {
        try {
            await API_ADM.put(`revendas/put/${id}`, data)
            showToast('Perfil atualizado com sucesso', 3000, 'success');
        } catch (err) {
            showToast(`erro: ${err?.response?.data?.detail}`, 3000, 'error');
        }
    }

    useEffect(() => {
        getById()
    }, [])

    return (
        <div style={{ padding: 30 }}>
            {data && <Card>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr) 300px", gap: 15, marginBottom: "3rem" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Razão Social</span>
                        <input name="razao_social" value={data?.razao_social || ""} onChange={handleChange} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Fantasia</span>
                        <input name="fantasia" value={data?.fantasia} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>CNPJ</span>
                        <input name="cnpj" value={data?.cnpj} onChange={handleChange} />
                    </div>
                    {/* <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>CPF</span>
                        <input name="cpf" onChange={handleChange} />
                    </div> */}

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Contato</span>
                        <input name="contato" value={data?.contato} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Email</span>
                        <input name="email" value={data?.email} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Endereço</span>
                        <input name="logradouro" value={data?.logradouro} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Complemento</span>
                        <input name="complemento" value={data?.complemento} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Número</span>
                        <input name="numero" value={data?.numero} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Bairro</span>
                        <input name="bairro" value={data?.bairro} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>CEP</span>
                        <input name="cep" value={data?.cep} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Telefone</span>
                        <input name="telefone" value={data?.telefone} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Celular</span>
                        <input name="celular" value={data?.celular} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Chave Pix</span>
                        <input name="acerto_chave_pix" value={data?.acerto_chave_pix} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Banco</span>
                        <input name="acerto_banco" value={data?.acerto_banco} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Tipo de chave (pix)</span>
                        <input name="acerto_tipo_chave_pix" value={data?.acerto_tipo_chave_pix} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Nome do favorecido</span>
                        <input name="acerto_favorecido" value={data?.acerto_favorecido} onChange={handleChange} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <span>Dia do pagamento</span>
                        <input name="acerto_dia_pagto" value={data?.acerto_dia_pagto} onChange={handleChange} />
                    </div>
                </div>
                <button className="button" onClick={handleSave}>Salvar</button>
            </Card> }
        </div>
    )
}