import { useEffect, useState } from "react"
import { API_ADM } from "../../services/ApiAdmin"
import { useParams } from "react-router-dom"
import TabFaturas from "../../components/common/tabs/TabCobrancas"
import TabelaCobrancas from "../../components/common/table/TableCobrancas"

export default function RevendaCobrancas() {
    const [data, setData] = useState([])
    const [status, setStatus] = useState("aberto")

    const { id } = useParams()

    const get_cobrancas = async () => {
        try {
            const res = await API_ADM.get(`/cobranca/get/revenda/${id}`, {
                params: {
                    situacao: status
                }
            })
            // console.log(res)
            setData(res?.data?.cobrancas)
            // console.log(res?.data?.cobrancass)
        } catch (err) {
            throw err
        }
    }

    useEffect(() => {
        get_cobrancas()
    }, [status])

    return (
        <>
            <TabFaturas
                onChange={setStatus}
                content={<TabelaCobrancas data={data} count={data.length} />}
            />

        </>
    )
}