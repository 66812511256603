import { Link, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import './index.css';
import { IoIosAddCircle } from "react-icons/io";


export default function TabelaSaldo({ data, count }) {
  const navigate = useNavigate()

  if (!data) {
    return <div>Carregando dados... ou nenhum dado disponível</div>;
  }

  return (
    <div className="table-container">

      <section className="table-header">
        <div className='table-header-data'> Lista de revendas</div>
        <div className='table-header-data'> {count} registros</div>
      </section>


      <section className="table-body">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Dados da Revenda</th>
              <th>Cidade</th>
              <th>Contato</th>
              <th>Chave PIX</th>
              <th>Créditos</th>
              <td>Acertos</td>
              <td>Dia Acerto</td>
              <td>Saldo a Pagar</td>
              <td></td>
            </tr>

          </thead>
          <tbody>
            {
              data.map((item) => (
                <tr key={item.detalhes_revenda.id}>
                  <td>{item.detalhes_revenda.id}</td>
                  <td>
                    <div className='text-primary'>{item.detalhes_revenda.fantasia}</div>
                    <div>{item.detalhes_revenda.razao_social}</div>
                    <div>{item.detalhes_revenda.cnpj}</div>
                  </td>

                  <td>{item.detalhes_revenda.cidade}-{item.detalhes_revenda.uf}</td>

                  <td>
                    <div className='text-primary'>{item.detalhes_revenda.contato}</div>
                    <div>{item.detalhes_revenda.email}</div>
                    <div>{item.detalhes_revenda.celular}</div>
                  </td>

                  <td>
                    <div>{item.detalhes_revenda.acerto_chave_pix}</div>
                    <div>{item.detalhes_revenda.acerto_tipo_chave_pix}</div>
                    <div>{item.detalhes_revenda.acerto_favorecido}</div>
                    <div>{item.detalhes_revenda.acerto_banco}</div>
                  </td>

                  {/* <td> 
                    <Link to={`/revendas/detalhe/${item.id}`}>
                          <i><FiMenu /></i>                    
                    </Link>                 
                  </td> */}

                  <td>
                    <div>{item.creditos.toFixed(2).replace(".", ",")}</div>
                  </td>
                  <td>
                    <div>{item.acertos.toFixed(2).replace(".", ",")}</div>
                  </td>

                  <td>
                    <span>{item.detalhes_revenda.acerto_dia_pagto}</span>
                  </td>
                  <td>
                    <div>{item.diferenca.toFixed(2).replace(".", ",")}</div>
                  </td>

                  <td>
                    <span>Acertos</span>
                    <button
                      className="button"
                      style={{ width: "50px", padding: 4 }}
                      title="Pagar"
                      onClick={() => {
                        navigate(`/revendas/detalhe/${item.detalhes_revenda.id}/financeiro`)
                      }}
                    >
                      <i><IoIosAddCircle /></i>
                    </button>
                  </td>



                </tr>
              ))
            }

          </tbody>
        </table>
      </section>
    </div>
  );
}