import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import './index.css';

export default function TabelaRevendas( {data, count}) {
  if (!data) {
    return <div>Carregando dados... ou nenhum dado disponível</div>;
  }

  return (
    <div className="table-container"> 

      <section className="table-header">  
          <div className='table-header-data'> Lista de revendas</div>
          <div className='table-header-data'> {count} registros</div>            
      </section>


      <section className="table-body">
        <table>
          <thead>
              <tr>
              <th>#</th>
                <th>Dados da Revenda</th>
                <th>Cidade</th>
                <th>Contato</th>
                <th>Observações</th>
                <th>Actions</th>
              </tr>

          </thead>
          <tbody>
            {
              data.map((item) =>(
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>
                    <div className='text-primary'>{item.fantasia}</div>
                    <div>{item.razao_social}</div>
                    <div>{item.cnpj}</div>
                  </td>
                  <td>{item.cidade}</td>
                  <td>
                    <div className='text-primary'>{item.contato}</div>
                    <div>{item.email}</div>
                    <div>{item.celular}</div>
                  </td>
                  <td>{item.observacao}</td>
                  <td> 
                    <Link to={`/revendas/detalhe/${item.id}`}>
                          <i><FiMenu /></i>                    
                    </Link>                 
                  </td>



                </tr>
              ))
            }
          
          </tbody>
        </table>         
      </section>
    </div>
  );
}