import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../../context/AuthContext';
import { FiUser, FiLock } from "react-icons/fi";
import { ToastContext } from '../../common/toast/ToastContext';
import './index.css';
import logo from '../../../assets/imgs/logo-rota-2024.png';

const Login = () => {
  const { login } = useContext(AuthContext); // Alterado para login
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const grupo = 'Admin'; 
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();



  const handleLogin = async (event) => {
    event.preventDefault();

    const loginData = {
      username,
      password,
      grupo
    };

    try {

      const response = await axios.post(`${process.env.REACT_APP_API_AUTH}/auth/user/login`, loginData);
      const data = response.data;

       
      login(data.access_token, data.user);
      showToast('Login sucedido!', 3000, 'success');
      navigate('/'); 
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      showToast(`Login falhou: ${errorMessage}`, 3000, 'error');
      
    }
  };


  return (
    <div className='container-login'>
   
      <div className='longin-left'>
        <img src={logo} />
         <h1>Bem vindo a Rota tecnologia</h1>
         <h3>passport para Rota Tecnologia</h3>
         
        
      </div>

      <div className='longin-rigth'>
       
         <div className='login-body'>
          
           <div className='login-header'>
                 <h1>Login</h1>                 
           </div>


           <div className='login-form'>
              <form onSubmit={handleLogin}>
                <div className='input-user'>


                <label id="email-label" for="email" className="chakra-form__label css-1p72pwq">Email
                   <span role="presentation" aria-hidden="true" className="chakra-form__required-indicator css-1tfjd1n">*</span>
                </label>


                  <div className='input-box-user'>
                    <FiUser className='input-icon' />
                    <input 
        
                      type='text' 
                      placeholder='Username' 
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      name="user"
          
                    /> 
                  </div>
                  <div className='input-box-pass'>                   
                    <label id="password-label" for="password" className="chakra-form__label css-1p72pwq">Senha
                      <span role="presentation" aria-hidden="true" className="chakra-form__required-indicator css-1tfjd1n">*</span>
                    </label>

                    <FiLock className='input-icon' />
                    <input 
                      type='password' 
                      placeholder='Password' 
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                    /> 
                  </div>
                </div>

                 
                  <button className='button-dark' type='submit'>Login</button> 
              
              </form>
         </div>
          
          </div> 
      </div>


     

    </div>
  );
};

export default Login;
