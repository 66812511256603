// ToastProvider.js
import React, { createContext, useState, useEffect } from 'react';
import Toast from './Toast';
import './index.css';

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState(null);

  const showToast = (message, duration = 3000, status = 'default') => {
    setToast({ message, duration, status });
  };

  const hideToast = () => {
    setToast(null);
  };

  useEffect(() => {
    let timer;
    if (toast) {
      timer = setTimeout(() => {
        hideToast();
      }, toast.duration);
    }
    return () => clearTimeout(timer);
  }, [toast]);

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      {toast && <Toast {...toast} onClose={hideToast} />}
    </ToastContext.Provider>
  );
};

export { ToastProvider, ToastContext };
