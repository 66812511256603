import React, { useEffect, useState } from 'react';
import { CgUserList, CgInsertBefore, CgPen, CgQr } from 'react-icons/cg'
import './index.css';
import { API_ADM } from '../../../services/ApiAdmin';
import { Link, NavLink, useParams } from 'react-router-dom';

const HeaderRevendaDetalhe = () => {
  const [activeLink, setActiveLink] = useState(1);
  const [revenda, setRevenda] = useState(null)

  const { id } = useParams();

  const LinkClick = (index) => {
    setActiveLink(index);
  };

  const fetchData = async () => {
    try {
      const response = await API_ADM.get(`/revendas/get/${id}`);
      setRevenda(response.data.revendas[0])
      // console.log(response.data.revendas[0])
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='page-header'>
      <div className='page-header-itens'>
        <div className='page-header-itens-title'>{revenda?.fantasia}</div>
        <span>{id || revenda?.cnpj || revenda?.cpf}   {revenda?.razao_social} </span>
        <span>Cidade: {revenda?.cidade} - {revenda?.uf}</span>
        <span>Fone: {revenda?.celular}</span>
        <span>Contato: {revenda?.contato}</span>
      </div>

      <div className='page-header-nav'>
        <ul>
          <li>
            <NavLink to={""} end>
              <i><CgUserList /></i>
              Contribuintes
            </NavLink>
          </li>
          <li>
            <NavLink to={"cobrancas"}>
            <i><CgQr /></i>
              Cobranças
            </NavLink>
          </li>

          <li>
            <NavLink to={"financeiro"}>
            <i><CgInsertBefore /></i>
              Financeiro
            </NavLink>
          </li>

          <li>
            <NavLink to={"dados"}>
            <i><CgPen /></i>
              Dados da revenda
            </NavLink>
          </li>
        </ul>
      </div>

    </div>
  );
};

export default HeaderRevendaDetalhe;
