import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../sidebar/Sidebar';
import Profile from '../../common/profile/Profile';
import { FiBell, FiSun } from 'react-icons/fi';
import { FaMoon } from 'react-icons/fa';
import Logout from '../../layout/logout/Logout';


import './index.css';

const Layout = () => {
  const [theme, setTheme] = useState('');

  // const changeTheme = (newTheme) => {
  //   setTheme(newTheme);
  //   localStorage.setItem('theme', newTheme);
  //   document.body.classList.toggle(newTheme)
  // };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    document.documentElement.setAttribute("data-theme", savedTheme)
  }, []);

  const toggleTheme = () => {
    // const newTheme = theme === 'dark' ? 'light' : 'dark';
    // changeTheme(newTheme);

    const isLight = localStorage.getItem('theme') === "light"
    if (isLight) {
      document.documentElement.setAttribute("data-theme", "dark")
      localStorage.setItem('theme', "dark")
    } else {
      document.documentElement.setAttribute("data-theme", "light")
      localStorage.setItem('theme', "light")
    }
  };

  return (
    <div className={`container ${theme}`}>
      <div className="header">
        <div className="text-panel">Painel Administrativo</div>
        <div className="header-right">



          <div className="theme-alternate" onClick={toggleTheme}>
            {theme === 'dark' ? (
              <FaMoon size={20} color="white" />
            ) : (
              <FiSun size={20} color="black" />
            )}
          </div>


          <div className="notications">
            <i><FiBell /></i>
            <span>1</span>
          </div>

          <div className="profile">
            <Profile />
          </div>



          <div>
            <Logout />
          </div>
        </div>
      </div>

      <div className="nav sidebar-layout">
        <Sidebar />
      </div>
      <div className="main">
        <Outlet />
      </div>
      <div className="aside">Conteudo Relacionado</div>
      <div className="footer">
        Rota tecnologia 2023 - Todos os direitos reservados
      </div>
    </div>
  );
};

export default Layout;