import React from "react";
import './index.css';

const Input = ({ searchInputChange, placeholder, name, onKeyDown, icon }) => {
    return (
        <div className="input-container">
            <input
                onChange={searchInputChange}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                name={name}
                className="input"
            />
            {icon && <div className="input-icon">{icon}</div>}
        </div>
    );
}

export default Input;
